import { SurveyQuizComponent } from "./components/survey-quiz/survey-quiz.component";
import { OccupantFeedbackComponent } from "./components/occupant-feedback/occupant-feedback.component";
import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { SuccessPageComponent } from "./components/success-page/success-page.component";


const routes: Routes = [
    { path: 'success', component: SuccessPageComponent },
    { path:':id' , component: SurveyQuizComponent, data: ['./projectdetails'] },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })


 export class SurveyRoutingModule{}

