import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { SurveyContainer } from '../../common/survey.container';

@Component({
  selector: 'app-response-average-item',
  templateUrl: './response-average-item.component.html',
  styleUrls: ['./response-average-item.component.scss']
})
export class ResponseAverageItemComponent extends SurveyContainer {
  
  @Input() res:any;
  @Output() newItemEvent = new EventEmitter<any>();
  ratings:any;
  color:any;
  direction:any;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.calculateQuestionWeightedAverage();
  }

  calculateQuestionWeightedAverage(){
    const numberOfRespondents: any = Object.values(this.res).reduce((a:any, b: any) => a + b, 0);

    const questionWeight = this.res.VerySatisfaying*4 + this.res.QuiteSatisfying*3 +
      this.res.NotSatisfying*2 + this.res.NotAtAllSatisfactory*1
                      
    const weightedAverage = questionWeight / numberOfRespondents;
    
    if(weightedAverage>=1 && weightedAverage<1.5){
      this.ratings=this.translate.instant('labels.response4');
      this.color="Red";
      this.direction="down";
    } 
    else if(weightedAverage>=1.5 && weightedAverage<2.5){
      this.ratings=this.translate.instant('labels.response3');
      this.color="Yellow";
      this.direction="down";
    } 
    else if(weightedAverage>=2.5 && weightedAverage<3.5){
      this.ratings=this.translate.instant('labels.response2');
      this.color="lightGreen";
      this.direction="up";
    } 
    else if(weightedAverage>=3.5 && weightedAverage<=4.0){
      this.ratings=this.translate.instant('labels.response1');
      this.color="DarkGreen";
      this.direction="up";
    } 
    else{
      this.ratings=this.translate.instant('labels.response5')
    } 
    
    this.addNewItem(this.ratings)
  }

  addNewItem(value: string) {
    this.newItemEvent.emit(value);
  }


  

  





}
