<div class="container-fluid" *ngIf="surveyResponses">
  <div class="row content sidenav">
    <div class="msg mb-3 p-2 pl-3 text-base" [innerHTML]=" 'messages.surveyResponse' | translate: { surveyResponsesCount: surveyResponses.length }">
    </div>

    <div class="grid mt-0 mb-3 gap-5" *ngIf = "projectDetails.status == 'active'">
      <div class="col">
        <div class="text-sm">
          {{ 'paragraphs.surveyMessage1' | translate }}
        </div>
        <div class="grid m-0 mt-3 gap-3">
          <div>
            <p-button
              label="{{ 'buttons.seeQuiz' | translate }}"
              styleClass="p-button-outlined"
              icon="pi pi-eye"
              (onClick)="openInNewTab()"
            ></p-button>
          </div>
          <div *oktaHasAnyGroup="['Admins', 'BE subcontractors']">
            <p-button
              label="{{ 'buttons.exportExcel' | translate }}"
              styleClass="p-button-primary"
              (click)="getExportData(projectDetails.id)"
            ></p-button>
          </div>
        </div>
      </div>
      <div class="col">
        <p>{{ 'paragraphs.surveyMessage2' | translate }}
        </p>
        <div class="mb-3 flex">
          <input
            pInputText
            #url
            type="text"
            class="form-control mb-3 p-2"
            placeholder=""
            value="{{ hostName }}/#/survey/{{ projectDetails.id }}"
            aria-label="Username"
            aria-describedby="basic-addon1"
            readonly
          />
          <p-button
            label="{{ 'buttons.copy' | translate }}"
            styleClass="p-button-warning"
            icon="pi pi-copy"
            (click)="copyQuizURL(url)"
          ></p-button>
        </div>
      </div>
    </div>
  </div>
</div>

<p-accordion
  styleClass="flex flex-column gap-2"
  expandIcon="pi pi-plus"
  collapseIcon="pi pi-minus"
  iconPos="end"
  *ngIf="surveyResponses != 0"
>
  <!-- Space -->
  <p-accordionTab>
    <ng-template pTemplate="header">
      <div class="flex align-items-center">
        <span class="vertical-align-right"
          ><span class="mr-2"><img src="assets/images/space.svg" /></span>
          {{ 'headers.space' | translate }}</span
        >
        <i class="mr-3"></i>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <div class="flex flex-wrap gap-3">
        <div class="p-4 surface-card shadow-2 border-round-2xl flex-33">
          <div class="text-base font-medium text-900 mb-3 flex gap-2 align-items-baseline h-63">
            <i class="pi pi-check-square"></i><span>{{ 'messages.space1' | translate }}</span>
          </div>
          <div class="font-medium text-700 mb-3">
            <app-response-average-item
              [res]="getPersonsReactionByChoices('space', 'room_os_fr')"
              (newItemEvent)="
                calculateRatingsSecLevel($event, 'space', 'room_os_fr')
              "
            ></app-response-average-item>
          </div>
        </div>

        <div class="p-4 surface-card shadow-2 border-round-2xl flex-33">
          <div class="text-base font-medium text-900 mb-3 h-42 flex gap-2 align-items-baseline h-63">
            <i class="pi pi-check-square"></i><span>{{ 'messages.space2' | translate }}</span>
          </div>
          <div class="font-medium text-500 mb-3">
            <app-response-average-item
              [res]="getPersonsReactionByChoices('space', 'decoration_os_fr')"
              (newItemEvent)="
                calculateRatingsSecLevel($event, 'space', 'decoration_os_fr')
              "
            ></app-response-average-item>
          </div>
        </div>

        <div class="p-4 surface-card shadow-2 border-round-2xl flex-33">
          <div class="text-base font-medium text-900 mb-3 flex gap-2 align-items-baseline">
            <i class="pi pi-check-square"></i><span>{{ 'messages.space3' | translate }}</span>
          </div>
          <div class="font-medium text-700 mb-3">
            <app-response-average-item
              [res]="getPersonsReactionByChoices('space', 'flow_os_fr')"
              (newItemEvent)="
                calculateRatingsSecLevel($event, 'space', 'flow_os_fr')
              "
            ></app-response-average-item>
          </div>
        </div>
      </div>

      <div class="border-top-1 border-black-alpha-60 mt-3 pt-3">
        <h5><i class="pi pi-comment flex align-items-center justify-content-center bg-purple-100 border-round p-2 mr-2 inline-flex"></i> {{ 'messages.collaborators' | translate }} [ {{ getNoOfComments('space') }} {{'messages.comments' | translate}}]</h5>
        <h6 class="mt-3">{{ 'messages.space4' | translate }}</h6>
      </div>
      <app-comments-items  class="flex flex-wrap gap-3"
        [item]="getCommentsForSection('space', 'general_os_fr')"
      ></app-comments-items>
    </ng-template>
  </p-accordionTab>

  <!-- ACOUSTIC  -->
  <p-accordionTab>
    <ng-template pTemplate="header">
      <div class="flex align-items-center">
        <span class="vertical-align-right"
          ><span class="mr-2"><img src="assets/images/acoustic.svg" /></span>
          {{ 'headers.acoustic1' | translate }}</span
        >
        <i class="mr-3"></i>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
        <div class="flex flex-wrap gap-3">
        <div class="p-4 surface-card shadow-2 border-round-2xl flex-33">
     <div class="text-base font-medium text-900 mb-3 h-42 flex gap-2 align-items-baseline">
        <i class="pi pi-check-square"></i><span>
          {{ 'messages.acoustic2' | translate }}</span>
    </div>
      <app-response-average-item
        [res]="getPersonsReactionByChoices('acoustics', 'noise_level_os_fr')"
        (newItemEvent)="
          calculateRatingsSecLevel($event, 'acoustics', 'noise_level_os_fr')
        "
      ></app-response-average-item>
      </div>

       <div class="p-4 surface-card shadow-2 border-round-2xl flex-33">
     <div class="text-base font-medium text-900 mb-3 h-42 flex gap-2 align-items-baseline">
        <i class="pi pi-check-square"></i><span>
          {{ 'messages.acoustic3' | translate }}</span></div>
      <app-response-average-item
        [res]="getPersonsReactionByChoices('acoustics', 'sound_proofing_os_fr')"
        (newItemEvent)="
          calculateRatingsSecLevel($event, 'acoustics', 'sound_proofing_os_fr')
        "
      ></app-response-average-item>
</div>
</div>
<div class="border-top-1 border-black-alpha-60 mt-3 pt-3">
    <h5><i class="pi pi-comment flex align-items-center justify-content-center bg-purple-100 border-round p-2 mr-2 inline-flex"></i> {{ 'messages.collaborators' | translate }} [ {{ getNoOfComments('acoustics') }} {{'messages.comments' | translate}}]</h5>
    <h6 class="mt-3">{{ 'messages.acoustic4' | translate }}</h6>
  </div>
    
      <app-comments-items class="flex flex-wrap gap-3"
        [item]="getCommentsForSection('acoustics', 'general_os_fr')"
      ></app-comments-items>

      <h6 class="mt-3 border-top-1 border-black-alpha-60 mt-3 pt-3">{{ 'messages.typesOfNoise' | translate }}</h6>
      <app-comments-items class="flex flex-wrap gap-3"
        [item]="getCommentsForSection('acoustics', 'disturbing_noises_os_fr')"
      ></app-comments-items>

      <h6 class="mt-3 border-top-1 border-black-alpha-60 mt-3 pt-3">{{ 'messages.protectFromNoise' | translate }}</h6>
      <app-comments-items class="flex flex-wrap gap-3"
        [item]="getCommentsForSection('acoustics', 'protection_os_fr')"
      ></app-comments-items>
    </ng-template>
  </p-accordionTab>

  <!-- Air -->
  <p-accordionTab>
    <ng-template pTemplate="header">
      <div class="flex align-items-center">
        <span class="vertical-align-right"
          ><span class="mr-2"><img src="assets/images/indoor-air.svg" /></span>
          {{ 'headers.air' | translate }}</span
        >
        <i class="mr-3"></i>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
        <div class="flex flex-wrap gap-3">
            <div class="p-4 surface-card shadow-2 border-round-2xl flex-33">
         <div class="text-base font-medium text-900 mb-3 flex gap-2 align-items-baseline">
            <i class="pi pi-check-square"></i><span>{{ 'messages.air1' | translate }}</span></div>
      <app-response-average-item
        [res]="getPersonsReactionByChoices('air', 'general_os_fr')"
        (newItemEvent)="
          calculateRatingsSecLevel($event, 'air', 'general_os_fr')
        "
      ></app-response-average-item>
      </div>
      </div>

      <div class="border-top-1 border-black-alpha-60 mt-3 pt-3">
        <h5><i class="pi pi-comment flex align-items-center justify-content-center bg-purple-100 border-round p-2 mr-2 inline-flex"></i> {{ 'messages.collaborators' | translate }} [ {{ getNoOfComments('air') }} {{'messages.comments' | translate}}]</h5>
        <h6 class="mt-3">{{ 'messages.air2' | translate }}</h6>
      </div>

      <app-comments-items class="flex flex-wrap gap-3"
        [item]="getCommentsForSection('air', 'general_os_fr')"
      ></app-comments-items>
    </ng-template>
  </p-accordionTab>

  <!-- Light -->
  <p-accordionTab>
    <ng-template pTemplate="header">
      <div class="flex align-items-center">
        <span class="vertical-align-right"
          ><span class="mr-2"><img src="assets/images/space.svg" /></span>
          {{ 'headers.light' | translate }}</span
        >
        <i class="mr-3"></i>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
        <div class="flex flex-wrap gap-3">
            <div class="p-4 surface-card shadow-2 border-round-2xl flex-33">
         <div class="text-base font-medium text-900 mb-3 h-42 flex gap-2 align-items-baseline">
            <i class="pi pi-check-square"></i><span>
              {{ 'messages.light1' | translate }}</span></div>
      
      <app-response-average-item
        [res]="getPersonsReactionByChoices('luminosity', 'natural_os_fr')"
        (newItemEvent)="
          calculateRatingsSecLevel($event, 'luminosity', 'natural_os_fr')
        "
      ></app-response-average-item>
    </div>

      <div class="p-4 surface-card shadow-2 border-round-2xl flex-33">
        <div class="text-base font-medium text-900 mb-3 flex gap-2 align-items-baseline">
            <i class="pi pi-check-square"></i><span>{{ 'messages.light2' | translate }}</span></div>
      <app-response-average-item
        [res]="getPersonsReactionByChoices('luminosity', 'artificial_os_fr')"
        (newItemEvent)="
          calculateRatingsSecLevel($event, 'luminosity', 'artificial_os_fr')
        "
      ></app-response-average-item>
      </div>
      </div>
      <div class="border-top-1 border-black-alpha-60 mt-3 pt-3">
        <h5><i class="pi pi-comment flex align-items-center justify-content-center bg-purple-100 border-round p-2 mr-2 inline-flex"></i> {{ 'messages.collaborators' | translate }} [ {{ getNoOfComments('luminosity') }} {{'messages.comments' | translate}}]</h5>
        <h6 class="mt-3">{{ 'messages.light3' | translate }}</h6>
      </div>

      <app-comments-items class="flex flex-wrap gap-3"
        [item]="getCommentsForSection('luminosity', 'general_os_fr')"
      ></app-comments-items>
    </ng-template>
  </p-accordionTab>

  <!-- Temperature -->
  <p-accordionTab>
    <ng-template pTemplate="header">
      <div class="flex align-items-center">
        <span class="vertical-align-right"
          ><span class="mr-2"><img src="assets/images/temperature.svg" /></span>
          {{ 'headers.temperature' | translate }}</span
        >
        <i class="mr-3"></i>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
        <div class="flex flex-wrap gap-3">
            <div class="p-4 surface-card shadow-2 border-round-2xl flex-33">
         <div class="text-base font-medium text-900 mb-3 flex gap-2 align-items-baseline">
            <i class="pi pi-check-square"></i><span>{{ 'messages.temperature1' | translate }}</span></div>
      <app-response-average-item
        [res]="getPersonsReactionByChoices('temperature', 'temperature_os_fr')"
        (newItemEvent)="
          calculateRatingsSecLevel($event, 'temperature', 'temperature_os_fr')
        "
      ></app-response-average-item>
      </div>

      <div class="p-4 surface-card shadow-2 border-round-2xl flex-33">
        <div class="text-base font-medium text-900 mb-3 flex gap-2 align-items-baseline h-42">
            <i class="pi pi-check-square"></i><span>{{ 'messages.temperature2' | translate }}</span></div>
      <app-response-average-item
        [res]="getPersonsReactionByChoices('temperature', 'heating_os_fr')"
        (newItemEvent)="
          calculateRatingsSecLevel($event, 'temperature', 'heating_os_fr')
        "
      ></app-response-average-item>
      </div>
      <div class="p-4 surface-card shadow-2 border-round-2xl flex-33">
        <div class="text-base font-medium text-900 mb-3 flex gap-2 align-items-baseline">
            <i class="pi pi-check-square"></i><span>{{ 'messages.temperature3' | translate }}</span></div>
      <app-response-average-item
        [res]="
          getPersonsReactionByChoices('temperature', 'air_conditioning_os_fr')
        "
        (newItemEvent)="
          calculateRatingsSecLevel(
            $event,
            'temperature',
            'air_conditioning_os_fr'
          )
        "
      ></app-response-average-item>
      </div>
      </div>

      <div class="border-top-1 border-black-alpha-60 mt-3 pt-3">
        <h5><i class="pi pi-comment flex align-items-center justify-content-center bg-purple-100 border-round p-2 mr-2 inline-flex"></i> {{ 'messages.collaborators' | translate }} [ {{ getNoOfComments('temperature') }} {{'messages.comments' | translate}}]</h5>
        <h6 class="mt-3">{{ 'messages.temperature4' | translate }}</h6>
      </div>

      <app-comments-items class="flex flex-wrap gap-3"
        [item]="getCommentsForSection('temperature', 'general_os_fr')"
      ></app-comments-items>
    </ng-template>
  </p-accordionTab>

  <!-- InSummary -->
  <p-accordionTab>
    <ng-template pTemplate="header">
      <div class="flex align-items-center">
        <span class="vertical-align-right"
          ><span class="mr-2"><img src="assets/images/summary.svg" /></span>
          {{ 'headers.summary' | translate }}</span
        >
        <i class="mr-3"></i>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
        <div class="mt-3 pt-3">
            <h5><i class="pi pi-comment flex align-items-center justify-content-center bg-purple-100 border-round p-2 mr-2 inline-flex"></i> {{ 'messages.collaborators' | translate }} [ {{ getNoOfComments('summary') }} {{'messages.comments' | translate}}]</h5>
            <h6 class="mt-3">{{ 'messages.summary1' | translate }}</h6>
        </div>
      
      <app-comments-items class="flex flex-wrap gap-3"
        [item]="getCommentsForSection('summary', 'improvements_os_fr')"
      ></app-comments-items>

      <h6 class="mt-3">
        {{ 'messages.summary2' | translate }}
      </h6>
      <app-comments-items class="flex flex-wrap gap-3"
        [item]="getCommentsForSection('summary', 'other_rooms_os_fr')"
      ></app-comments-items>
    </ng-template>
  </p-accordionTab>
</p-accordion>

<!-- Dialog for Export
<p-dialog header="{{ 'buttons.exportExcel' | translate }}" [(visible)]="visible" [style]="{width: '30vw'}">
    <p>{{ 'messages.confirmExport' | translate }}</p>
    <div class="grid m-0 mt-3 gap-3">
        <div>
            <p-button
                label="{{ 'buttons.exportExcel' | translate }}"
                styleClass="p-button-secondary"
                icon="pi pi-file-export"
                (click)="getExportData(projectDetails.id)"
            ></p-button>
        </div>
        <div>
            <p-button 
            label="{{ 'buttons.cancel' | translate }}" 
            styleClass="p-button-warning" 
            (click)="hideDialog()"
        ></p-button>
        </div>
    </div>
</p-dialog> -->