import { Component, Inject, OnInit } from '@angular/core';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../../authentication/model/user.model';
import { LanguageService } from '../../common/translation/language.service';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit {

  // public name$!: Observable<string>;
  userName: string = '';
  isSidebarButtonToggled = false;
  bodyTag = document.body;

  constructor(private authenticationService: AuthenticationService, 
    private translate: TranslateService, 
    private language: LanguageService,) {
  }

  public ngOnInit(): void {
    // this.name$ = this.authStateService.authState$.pipe(
    //   filter((authState: AuthState) => !!authState && !!authState.isAuthenticated),
    //   map((authState: AuthState) => authState.idToken?.claims.name ?? '')
    // );
    this.authenticationService.currentUser.subscribe((user: User) => {
      this.userName = user.fullName;
      this.translate.use(user.preferredLanguage);
      this.language.current = user.preferredLanguage;
      document.querySelector('html')?.setAttribute('lang', user.preferredLanguage);
    });

  }

  toggleSideBar() {    
    const sidenav = document.getElementById('sidenavMain');
    const mainContainer = document.getElementById('mainContainer');
    
    if (sidenav !== null && mainContainer!=null) {
      if (!this.isSidebarButtonToggled) {
        sidenav.classList.add('sidenav--collapse');  
        mainContainer.classList.add('main-container--expand');
        this.bodyTag.classList.add("body-sidenav-collapsed");  
        this.isSidebarButtonToggled = true;
  
      } else {  
        sidenav.classList.remove('sidenav--collapse');  
        mainContainer.classList.remove('main-container--expand');
        this.bodyTag.classList.remove("body-sidenav-collapsed");  
        this.isSidebarButtonToggled = false;  
      }
    }   
  
  }

  async logout() {
    this.authenticationService.logout();
  }
}
