import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpProvider } from 'src/app/core/common/http/http-provider.service';


@Injectable({
  providedIn:'root'
})
export class SurveyService extends HttpProvider{

  constructor(private httpClient: HttpClient) { 
    super(httpClient);

  }

  // public SaveObjectDetails(newObject: any){
  //   return this.post('saveObjectDetails', newObject);
  // }

  public saveSurveyResponse(projectId:string, answers:any){
    return this.post('Survey/postSurveyObjects',{projectId,answers});
  } 
  
  public getSurveyResponsesByProjectId(id: string) {
    return this.get('Survey/getSurveyObjects?projectId='+id);
  }

  public getExportFile(id:string) {
    return this.get('Survey/getExportObjects?projectId='+id);
  }
  
}
