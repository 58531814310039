import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SigninComponent } from '../core/authentication/components/sign-in/signin.component';
import { DefaultLayoutComponent } from '../core/layout/default-layout/default-layout.component';
import { OktaCallbackComponent, OktaAuthGuard } from '@okta/okta-angular';
import { SurveyQuizComponent } from '../modules/survey/components/_module';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'projects'
  },
  {
    path:'survey',
    children: [
      {
        path:'',
        loadChildren: () => 
          import('../modules/survey/survey.module').then((m) => { return m.SurveyModule; })
      }
    ]
  },
  {
    path: 'login/callback',
    component: OktaCallbackComponent,
  },
  {
    path: 'login',
    component: SigninComponent,
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [ OktaAuthGuard ],
    children: [
      {
        path: 'projects',
        loadChildren: () => 
          import('../modules/project/project.module').then((m) => { return m.ProjectModule; })
      },
      {
        path: 'dashboard',
        loadChildren: () => 
          import('../modules/dashboard/dashboard.module').then((m) => { return m.DashboardModule; })
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
