import { FeatureContainer } from '../../../core/common/container/feature.container';
import { SurveyInjector } from './survey.injector';
import { SurveyService } from '../services/survey.service';

export abstract class SurveyContainer extends FeatureContainer {
  //region variable 
  public surveyService: SurveyService
  //end region variable
  protected constructor() { 
    super(SurveyInjector);
    this.surveyService = SurveyInjector.get(SurveyService);
  }
}
