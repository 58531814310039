import { Inject, Injectable } from "@angular/core";
import { OKTA_AUTH } from "@okta/okta-angular";
import { OktaAuth } from "@okta/okta-auth-js";
import { Observable, BehaviorSubject } from "rxjs";
import { User } from "../model/user.model";

@Injectable()
export class AuthenticationService {

    userStorageKey: string = 'currentUser';

    public currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(@Inject(OKTA_AUTH) private oktaAuth : OktaAuth) {
        let user = JSON.parse(localStorage.getItem(this.userStorageKey) || '{}');
        this.currentUserSubject = new BehaviorSubject<User>(user);
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    public setUserInfo(user: User | undefined) {
        let defaultLang = "fr";    
        
        if (user) {
            var lang = user.preferredLanguage ? user.preferredLanguage : defaultLang;
            //var userLang = languages[lang as keyof typeof languages] || defaultLang;
            user.preferredLanguage = lang;

            localStorage.setItem(this.userStorageKey, JSON.stringify(user));
            this.currentUserSubject.next(user);
        }
    }

    public partialLogout() {
        localStorage.clear(); 
        //this.currentUserSubject.next(null);
    }

    async logout() {
        localStorage.clear(); 
        //this.currentUserSubject.next(null);
        await this.oktaAuth.signOut();     
    }

    public isLoggedin(): boolean {
        var user = localStorage.getItem(this.userStorageKey);
        return user != null;
    }
}