//#region Imports
import { Injector } from '@angular/core';
//#endregion Imports

export let SurveyInjector: Injector;

export function setSurveyInjector(injector: Injector) {
    if (SurveyInjector) {
    }
    else {
        SurveyInjector = injector;
    }
}