import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class BlobService {

  constructor() { }


  convertBytetoBlob(data: any): Blob {
    const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const byteCharacters = atob(data.fileContents);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }

}
