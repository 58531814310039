import { Component, Inject, OnInit } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth, Tokens } from '@okta/okta-auth-js';
import OktaSignIn from '@okta/okta-signin-widget';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../../services/authentication.service';
import { User } from '../../model/user.model';

const DEFAULT_ORIGINAL_URI = window.location.origin;

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  signIn: any;
  constructor(@Inject(OKTA_AUTH) public oktaAuth: OktaAuth, private authenticationService: AuthenticationService) {
    this.signIn = new OktaSignIn({
      baseUrl: environment.oidc.issuer.split('/oauth2')[0],
      clientId: environment.oidc.clientId,
      redirectUri: environment.oidc.redirectUri,
      logo: 'assets/images/LOGO-ECUME-v1.png',
      authClient: oktaAuth,
      useInteractionCodeFlow: environment.widget.useInteractionCodeFlow === 'true'
    });
  }

  ngOnInit() {
    const originalUri = this.oktaAuth.getOriginalUri();
    if (!originalUri || originalUri === DEFAULT_ORIGINAL_URI) {
      this.oktaAuth.setOriginalUri('/');
    }
    
    this.signIn.showSignInToGetTokens({
      el: '#sign-in-widget',
      scopes: environment.oidc.scopes
    }).then((tokens: Tokens) => {
      const userClaims = tokens.idToken?.claims;
      let user : User | undefined = this.getUserFromUserClaims(userClaims);
      this.authenticationService.setUserInfo(user);

      this.signIn.remove();
      this.oktaAuth.handleLoginRedirect(tokens);
    }).catch((err: any) => {
      // Typically due to misconfiguration
      throw err;
    });
  }

  ngOnDestroy() {
    this.signIn.remove();
  }

  getUserFromUserClaims(userClaims: any) : User | undefined {
    let user : User | undefined;
    if (userClaims) {
      user = new User(
          userClaims.sub,
          userClaims.firstName as string,
          userClaims.lastName as string,
          userClaims.name as string,
          userClaims.email as string,
          userClaims["countryCode"] as string,
          userClaims.preferredLanguage as string,
          userClaims["organization"] as string,
          userClaims["title"] as string,
          userClaims.phoneNumber as string,
          userClaims.organization as string,
          userClaims.streetAddress as string,
          userClaims.zipCode as number,
          userClaims.city as string,
          userClaims.state as string,
          userClaims.postalAddress as string,
          userClaims.groups as string
      );
    }
    return user;
  }

}
