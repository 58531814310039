<div class="card p-0 m-6 mt-4 mb-5 border-0">
    <div class="card-body p-0">
        <div class="surface-ground shadow-3 p-4">
    <h4>{{ 'messages.surveyQuestion' | translate }}</h4>
    <p class="text-base text-600">{{ 'messages.surveyTitle' | translate }}</p>

    <p class="text-base text-600">{{ 'messages.surveyMessage' | translate }}</p>
</div>
</div>
</div>

<!-- reactive form -->
<form [formGroup]="surveyForm" (ngSubmit)="onSubmit()">
    <div class="card p-0 m-6 mt-0 mb-4 border-0">
        <div class="card-body p-0">
            <div class="col bg-white shadow-3 p-4">
                <div class="text-900 text-xl font-medium mb-3"><span><img src="assets/images/space.svg"></span>{{ 'headers.space' | translate }}</div>
                
                <div class="opinion-block">
                    <div class="text-600 text-base mt-0 mb-4">
                        {{ 'messages.space1' | translate }}
                    </div>
                    <div class="surface-card shadow-2 border-round">
                        <div class="grid p-2">
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="noise_level_os_fr_s" value="2"></p-radioButton>
                                {{ 'labels.response1' | translate }}</label>
                            </div>
                            <div class="col p-1 flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="noise_level_os_fr_s" value="1"></p-radioButton>
                                {{ 'labels.response2' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="noise_level_os_fr_s" value="-1"></p-radioButton>
                                {{ 'labels.response3' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="noise_level_os_fr_s" value="-2"></p-radioButton>
                                {{ 'labels.response4' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="noise_level_os_fr_s" value="-500"></p-radioButton>
                                {{ 'labels.response5' | translate }}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="opinion-block mt-5">
                    <div class="text-600 text-base mt-0 mb-4">
                        {{ 'messages.space2' | translate }}
                    </div>
                    <div class="surface-card shadow-2 border-round">
                        <div class="grid p-2">
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                            <label>
                            <p-radioButton class="mr-2" formControlName="sound_proofing_os_fr_s" value="2"
                                inputId="ingredient1"></p-radioButton>
                                {{ 'labels.response1' | translate }}</label>
                        </div>

                        <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                            <label>
                            <p-radioButton class="mr-2"  formControlName="sound_proofing_os_fr_s" value="1"></p-radioButton>
                            {{ 'labels.response2' | translate }}</label>
                        </div>

                        <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                            <label>
                            <p-radioButton class="mr-2" formControlName="sound_proofing_os_fr_s" value="-1"></p-radioButton>
                            {{ 'labels.response3' | translate }}</label>
                        </div>

                        <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                            <label>
                            <p-radioButton class="mr-2" formControlName="sound_proofing_os_fr_s" value="-2"></p-radioButton>
                            {{ 'labels.response4' | translate }}</label>
                        </div>
                        <div class="col flex align-items-center justify-content-center surface-border cursor-pointer">
                            <label>
                            <p-radioButton class="mr-2" formControlName="sound_proofing_os_fr_s" value="-500"></p-radioButton>
                            {{ 'labels.response5' | translate }}</label>
                        </div>
                        </div>
                    </div>
                </div>

                <div class="opinion-block mt-5">
                    <div class="text-600 text-base mt-0 mb-4">
                        {{ 'messages.space3' | translate }}
                    </div>
                    <div class="surface-card shadow-2 border-round">
                        <div class="grid p-2">
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                            <label>
                            <p-radioButton class="mr-2" formControlName="flow_os_fr" value="2"
                                inputId="ingredient1"></p-radioButton>
                                {{ 'labels.response1' | translate }}</label>
                        </div>

                        <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                            <label>
                            <p-radioButton class="mr-2"  formControlName="flow_os_fr" value="1"></p-radioButton>
                            {{ 'labels.response2' | translate }}</label>
                        </div>

                        <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                            <label>
                            <p-radioButton class="mr-2" formControlName="flow_os_fr" value="-1"></p-radioButton>
                            {{ 'labels.response3' | translate }}</label>
                        </div>

                        <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                            <label>
                            <p-radioButton class="mr-2" formControlName="flow_os_fr" value="-2"></p-radioButton>
                            {{ 'labels.response4' | translate }}</label>
                        </div>
                        <div class="col flex align-items-center justify-content-center surface-border cursor-pointer">
                            <label>
                            <p-radioButton class="mr-2" formControlName="flow_os_fr" value="-500"></p-radioButton>
                            {{ 'labels.response5' | translate }}</label>
                        </div>
                        </div>
                    </div>
                </div>

                <div class="opinion-block mt-5">
                    <div class="text-600 text-base mt-0 mb-3">
                        {{ 'messages.space4' | translate }}
                    </div>
                    <div class="surface-card shadow-2 border-round">
                    <textarea maxlength="500" autocomplete="off" class="w-100" rows="3" cols="30" pInputTextarea formControlName="general_os_fr_s"></textarea>
                </div>
                </div>
            </div>

            <div class="col surface-ground shadow-3 p-4 mt-5">
                <div class="text-900 text-xl font-medium mb-3">
                <span><img src="assets/images/acoustic.svg"></span>
                {{ 'headers.acoustic1' | translate }}</div>
                
                <div class="opinion-block">
                    <div class="text-600 text-base mt-0 mb-4">
                        {{ 'messages.acoustic2' | translate }}
                    </div>
                    <div class="surface-card shadow-2 border-round">
                        <div class="grid p-2">
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="noise_level_os_fr_a" value="2"></p-radioButton>
                                {{ 'labels.response1' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="noise_level_os_fr_a" value="1"></p-radioButton>
                                {{ 'labels.response2' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="noise_level_os_fr_a" value="-1"></p-radioButton>
                                {{ 'labels.response3' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="noise_level_os_fr_a" value="-2"></p-radioButton>
                                {{ 'labels.response4' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="noise_level_os_fr_a" value="-500"></p-radioButton>
                                {{ 'labels.response5' | translate }}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="opinion-block mt-5">
                    <div class="text-600 text-base mt-0 mb-4">
                        {{ 'messages.acoustic3' | translate }}
                    </div>
                    <div class="surface-card shadow-2 border-round">
                        <div class="grid p-2">
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                            <label>
                            <p-radioButton class="mr-2" formControlName="sound_proofing_os_fr" value="2"
                                inputId="ingredient1"></p-radioButton>
                                {{ 'labels.response1' | translate }}</label>
                        </div>

                        <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                            <label>
                            <p-radioButton class="mr-2"  formControlName="sound_proofing_os_fr" value="1"></p-radioButton>
                            {{ 'labels.response2' | translate }}</label>
                        </div>

                        <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                            <label>
                            <p-radioButton class="mr-2" formControlName="sound_proofing_os_fr" value="-1"></p-radioButton>
                            {{ 'labels.response3' | translate }}</label>
                        </div>

                        <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                            <label>
                            <p-radioButton class="mr-2" formControlName="sound_proofing_os_fr" value="-2"></p-radioButton>
                            {{ 'labels.response4' | translate }}</label>
                        </div>
                        <div class="col flex align-items-center justify-content-center surface-border cursor-pointer">
                            <label>
                            <p-radioButton class="mr-2" formControlName="sound_proofing_os_fr" value="-500"></p-radioButton>
                            {{ 'labels.response5' | translate }}</label>
                        </div>
                        </div>
                    </div>
                </div>

                <div class="opinion-block mt-5">
                    <div class="text-600 text-base mt-0 mb-3">
                        {{ 'messages.acoustic4' | translate }}
                    </div>
                    <div class="surface-card shadow-2 border-round">
                    <textarea maxlength="500" autocomplete="off" class="w-100" rows="3" cols="30" pInputTextarea formControlName="general_os_fr_a"></textarea></div>
                </div>

                <div class="opinion-block mt-5">
                    <div class="text-600 text-base mt-0 mb-3">
                        {{ 'messages.typesOfNoise' | translate }}
                    </div>
                    <div class="surface-card shadow-2 border-round">
                    <textarea maxlength="500" autocomplete="off" class="w-100" rows="3" cols="30" pInputTextarea formControlName="disturbing_noises_os_fr"></textarea></div>
                </div>

                <div class="opinion-block mt-5">
                    <div class="text-600 text-base mt-0 mb-3">
                        {{ 'messages.protectFromNoise' | translate }}
                    </div>
                    <div class="surface-card shadow-2 border-round">
                    <textarea maxlength="500" autocomplete="off" class="w-100" rows="3" cols="30" pInputTextarea formControlName="protection_os_fr"></textarea></div>
                </div>
            </div>

            <div class="bg-white shadow-3 p-4 mt-5">
                <div class="text-900 text-xl font-medium mb-3"><span><img src="assets/images/indoor-air.svg"></span> {{ 'headers.air' | translate }}</div>
                
                <div class="opinion-block">
                    <div class="text-600 text-base mt-0 mb-4">
                        {{ 'messages.air1' | translate }}
                    </div>
                    <div class="surface-card shadow-2 border-round">
                        <div class="grid p-2">
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="general_os_fr_air" value="2"></p-radioButton>
                                {{ 'labels.response1' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="general_os_fr_air" value="1"></p-radioButton>
                                {{ 'labels.response2' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="general_os_fr_air" value="-1"></p-radioButton>
                                {{ 'labels.response3' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="general_os_fr_air" value="-2"></p-radioButton>
                                {{ 'labels.response4' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="general_os_fr_air" value="-500"></p-radioButton>
                                {{ 'labels.response5' | translate }}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="opinion-block mt-5">
                    <div class="text-600 text-base mt-0 mb-3">
                        {{ 'messages.air2' | translate }}
                    </div>
                    <div class="surface-card shadow-2 border-round">
                    <textarea maxlength="500" autocomplete="off" class="w-100" rows="3" cols="30" pInputTextarea formControlName="comment_air"></textarea></div>
                </div>
            </div>

            <div class="surface-ground shadow-3 p-4 mt-5">
                <div class="text-900 text-xl font-medium mb-3"><span><img src="assets/images/space.svg"></span> {{ 'headers.light' | translate }}</div>
                
                <div class="opinion-block">
                    <div class="text-600 text-base mt-0 mb-4">
                        {{ 'messages.light1' | translate }}
                    </div>
                    <div class="surface-card shadow-2 border-round">
                        <div class="grid p-2">
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="natural_os_fr" value="2"></p-radioButton>
                                {{ 'labels.response1' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="natural_os_fr" value="1"></p-radioButton>
                                {{ 'labels.response2' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="natural_os_fr" value="-1"></p-radioButton>
                                {{ 'labels.response3' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="natural_os_fr" value="-2"></p-radioButton>
                                {{ 'labels.response4' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="natural_os_fr" value="-500"></p-radioButton>
                                {{ 'labels.response5' | translate }}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="opinion-block mt-5">
                    <div class="text-600 text-base mt-0 mb-4">
                        {{ 'messages.light2' | translate }}
                    </div>
                    <div class="surface-card shadow-2 border-round">
                        <div class="grid p-2">
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="artificial_os_fr" value="2"></p-radioButton>
                                {{ 'labels.response1' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="artificial_os_fr" value="1"></p-radioButton>
                                {{ 'labels.response2' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="artificial_os_fr" value="-1"></p-radioButton>
                                {{ 'labels.response3' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="artificial_os_fr" value="-2"></p-radioButton>
                                {{ 'labels.response4' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="artificial_os_fr" value="-500"></p-radioButton>
                                {{ 'labels.response5' | translate }}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="opinion-block mt-5">
                    <div class="text-600 text-base mt-0 mb-3">
                        {{ 'messages.light3' | translate }}
                    </div>
                    <div class="surface-card shadow-2 border-round">
                    <textarea maxlength="500" autocomplete="off" class="w-100" rows="3" cols="30" pInputTextarea  formControlName="comment_light"
                    placeholder="{{ 'placeholders.surveyLight' | translate }}"></textarea></div>
                </div>
            </div>

            <div class="bg-white shadow-3 p-4 mt-5">
                <div class="text-900 text-xl font-medium mb-3">
                    <span><img src="assets/images/temperature.svg"></span> {{ 'headers.temperature' | translate }}</div>
                
                <div class="opinion-block">
                    <div class="text-600 text-base mt-0 mb-4">
                        {{ 'messages.temperature1' | translate }}
                    </div>
                    <div class="surface-card shadow-2 border-round">
                        <div class="grid p-2">
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="heating_os_fr" value="2"></p-radioButton>
                                {{ 'labels.response1' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="heating_os_fr" value="1"></p-radioButton>
                                {{ 'labels.response2' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="heating_os_fr" value="-1"></p-radioButton>
                                {{ 'labels.response3' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="heating_os_fr" value="-2"></p-radioButton>
                                {{ 'labels.response4' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="heating_os_fr" value="-500"></p-radioButton>
                                {{ 'labels.response5' | translate }}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="opinion-block mt-5">
                    <div class="text-600 text-base mt-0 mb-4">
                        {{ 'messages.temperature2' | translate }}
                    </div>
                    <div class="surface-card shadow-2 border-round">
                        <div class="grid p-2">
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="temperature_os_fr" value="2"></p-radioButton>
                                {{ 'labels.response1' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="temperature_os_fr" value="1"></p-radioButton>
                                {{ 'labels.response2' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="temperature_os_fr" value="-1"></p-radioButton>
                                {{ 'labels.response3' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="temperature_os_fr" value="-2"></p-radioButton>
                                {{ 'labels.response4' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="temperature_os_fr" value="-500"></p-radioButton>
                                {{ 'labels.response5' | translate }}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="opinion-block mt-5">
                    <div class="text-600 text-base mt-0 mb-4">
                        {{ 'messages.temperature3' | translate }}
                    </div>
                    <div class="surface-card shadow-2 border-round">
                        <div class="grid p-2">
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="air_conditioning_os_fr" value="2"></p-radioButton>
                                {{ 'labels.response1' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="air_conditioning_os_fr" value="1"></p-radioButton>
                                {{ 'labels.response2' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="air_conditioning_os_fr" value="-1"></p-radioButton>
                                {{ 'labels.response3' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center border-right-1 surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="air_conditioning_os_fr" value="-2"></p-radioButton>
                                {{ 'labels.response4' | translate }}</label>
                            </div>
                            <div class="col flex align-items-center justify-content-center surface-border cursor-pointer">
                                <label>
                                <p-radioButton class="mr-2" formControlName="air_conditioning_os_fr" value="-500"></p-radioButton>
                                {{ 'labels.response5' | translate }}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="opinion-block mt-5">
                    <div class="text-600 text-base mt-0 mb-3">
                        {{ 'messages.temperature4' | translate }}
                    </div>
                    <div class="surface-card shadow-2 border-round">
                    <textarea maxlength="500" autocomplete="off" class="w-100" rows="3" cols="30" pInputTextarea  formControlName="comment_temperature"
                    placeholder="{{ 'placeholders.surveyTemperature' | translate }}"></textarea></div>
                </div>
            </div>

            <div class="surface-ground shadow-3 p-4 mt-5">
                <div class="text-900 text-xl font-medium mb-3"><span><img src="assets/images/summary.svg"></span> {{ 'headers.summary' | translate }}</div>
                
                <div class="opinion-block">
                    <div class="text-600 text-base mt-0 mb-3">
                        {{ 'messages.summary1' | translate }}
                    </div>
                    <div class="surface-card shadow-2 border-round">
                    <textarea maxlength="500" autocomplete="off" class="w-100" rows="3" cols="30" pInputTextarea  formControlName="improvements_os_fr"
                    placeholder="{{ 'placeholders.surveySummary1' | translate }}"></textarea></div>
                </div>

                <div class="opinion-block mt-5">
                    <div class="text-600 text-base mt-0 mb-3">
                        {{ 'messages.summary2' | translate }}
                    </div>
                    <div class="surface-card shadow-2 border-round">
                    <textarea maxlength="500" autocomplete="off" class="w-100" rows="3" cols="30" pInputTextarea  formControlName="other_rooms_os_fr"
                    placeholder="{{ 'placeholders.surveySummary2' | translate }}"></textarea></div>
                </div>
            </div>

            
        <p-button type="submit" label="{{ 'buttons.submit' | translate }}" icon="pi pi-check" styleClass="mt-3"
        [disabled]="!surveyForm.valid"></p-button>
        </div>
    </div>
    <!-- Submit section -->
</form>

<h1 *ngIf="showMsg"> {{ 'messages.registerSuccess' | translate }} </h1>

