<main>  
    <!-- Sidenav -->
    <div id="sidenavMain" class="sidenav" #sidenavMain>  
        <div class="sidenave-toggle d-flex justify-content-start ms-3">
            <button (click)="toggleSideBar()" id="sidebarCollapse">
                <i class="pi pi-align-justify" style="font-size: 1.5rem"></i>
            </button>

        </div>
        <!-- Sidenav logo -->
        <div class="sidenav__header mt-3">
            <img class="sidenav__logo" src="assets\images\LOGO-ECUME-v1.png" alt="Ecume logo">
        </div> 
        
        <!-- Sidenav List-->
        <div class="sidenav__menu-wrapper">
            <ul class="sidenav__nav-pills">
                <li class="sidenav__nav-item">
                    <a routerLink="projects" routerLinkActive="active" class="sidenav__link">
                        <span class="icon-container">
                        <span *ngIf="isSidebarButtonToggled"><i class="sidenav-icon pi pi-folder mr-0"></i> <span class="inline text-base lg:text-xs lg:block link-text">{{ 'labels.myProjects' | translate }}</span></span></span>
                        <span class="link-text sidenav__link-label" *ngIf="!isSidebarButtonToggled"><i class="sidenav-icon pi pi-folder"></i> {{ 'labels.myProjects' | translate }}
                        <svg class="nav-arrow " xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112 126"><defs></defs><g transform="matrix(1.3333333,0,0,-1.3333333,0,126.66667)"><g transform="translate(42.2001,95.0004)"><path style="stroke:none" d="M 0,0 -42.2,-42.201 -29.9,-54.5 -8.7,-33.201 V -95 H 8.7 v 61.799 L 29.901,-54.5 42.2,-42.201 Z"></path></g></g></svg>
                    </span> 
                    </a>
                </li>
                <!-- <li class="sidenav__nav-item"><a class="sidenav__divider">.</a></li> -->
                <li class="sidenav__nav-item">
                    <a routerLink="dashboard" routerLinkActive="active" class="sidenav__link">
                        <span class="icon-container">
                        <span *ngIf="isSidebarButtonToggled"><i class="sidenav-icon pi pi-home mr-0"></i> <span class="inline text-base lg:text-xs lg:block link-text">{{ 'labels.dashboard' | translate }}</span> </span> </span>
                        <span class="link-text sidenav__link-label" *ngIf="!isSidebarButtonToggled"> <i class="sidenav-icon pi pi-home"></i> {{ 'labels.dashboard' | translate }}
                            <svg class="nav-arrow " xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112 126"><defs></defs><g transform="matrix(1.3333333,0,0,-1.3333333,0,126.66667)"><g transform="translate(42.2001,95.0004)"><path style="stroke:none" d="M 0,0 -42.2,-42.201 -29.9,-54.5 -8.7,-33.201 V -95 H 8.7 v 61.799 L 29.901,-54.5 42.2,-42.201 Z"></path></g></g></svg>
                        </span>
                    </a>
                </li>                
            </ul>                            
        </div>
        
        <div class="sidenav__footer">
            <div class="footer-username" *ngIf="userName">
                <p-avatar label={{userName[0]}} styleClass="mr-2" size="large" [style]="{ 'background-color': '#ffffff', color: 'rgb(0, 48, 58)' }" shape="circle"></p-avatar>
                {{userName}}
            </div>
            <a id="logout-button" class="item" (click)="logout()">
                <i class="pi pi-sign-out"></i> {{ 'buttons.logout' | translate }}  
            </a>
        </div>
    </div>


    <!-- Main Container -->
    <div id="mainContainer" class="main-container">
        <router-outlet></router-outlet>
    </div>
    <div>
    </div>
</main>

