import { Component } from '@angular/core';
import { FormBuilder, FormControlName, Validators } from '@angular/forms';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { SurveyResponse } from '../../entities/surveyResponse.entity';
import {Space} from '../../entities/space';
import { Acoustics } from '../../entities/acoustics';
import { Air } from '../../entities/air';
import { Luminosity } from '../../entities/luminosity';
import { Temperature } from '../../entities/temperature';
import { Summary } from '../../entities/summary';
import { SurveyService } from '../../services/survey.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { SurveyContainer } from '../../common/survey.container';
 
@Component({
  selector: 'app-survey-quiz',
  templateUrl: './survey-quiz.component.html',
  styleUrls: ['./survey-quiz.component.scss']
})
export class SurveyQuizComponent extends SurveyContainer {

  surveyForm:FormGroup;
  projectid:any;
  response:SurveyResponse;
  responseToString:any;
  showMsg:any=false;

  ngOnInit(): void {
   
  }
  constructor(private fb: FormBuilder,
    route: ActivatedRoute){
      super();
      const id: any = route.params.pipe(map(p => p?.['id']));
      this.projectid=id.destination._value.id;
      this.response = new SurveyResponse();
      this.surveyForm = this.fb.group({
      //Space
      noise_level_os_fr_s:['', [Validators.required]],
      sound_proofing_os_fr_s:['', [Validators.required]],
      flow_os_fr:['', [Validators.required]],
      general_os_fr_s:"",
      //Acoustic
      noise_level_os_fr_a:['', [Validators.required]],
      sound_proofing_os_fr:['', [Validators.required]],
      general_os_fr_a:'',
      disturbing_noises_os_fr:'',
      protection_os_fr:'',
      // Air
      general_os_fr_air:['', [Validators.required]],
      comment_air:"",
      // Light
      natural_os_fr:['', [Validators.required]],
      artificial_os_fr:['', [Validators.required]],
      comment_light:"",
      // Tempertature
      heating_os_fr:['', [Validators.required]],
      temperature_os_fr:['', [Validators.required]],
      air_conditioning_os_fr:['', [Validators.required]],
      comment_temperature:"",
      //Summary
      other_rooms_os_fr:"",
      improvements_os_fr:"",
    });

  }
 
  onSubmit(){
      this.buildResponse();
      this.responseToString = this.response;
      this.saveSurveyResponse();
  }

  saveSurveyResponse(){
    this.spinner.show();
    this.surveyService.saveSurveyResponse(this.projectid,this.responseToString).subscribe(res=>{
      this.spinner.hide();  
      if (res) {
          this.router.navigate(['/survey/success'])
        } else {
          alert("Error Occurred");
        }
    }, () => {
      this.spinner.hide();
      alert('Error Occurred');
    });
  }

  buildResponse(){
    this.response.space= new Space();
    this.response.space.ratings["room_os_fr"]=this.surveyForm.controls['noise_level_os_fr_s'].value;
    this.response.space.ratings["decoration_os_fr"]=this.surveyForm.controls['sound_proofing_os_fr_s'].value;
    this.response.space.ratings["flow_os_fr"]=this.surveyForm.controls['flow_os_fr'].value;
    this.response.space.comments["general_os_fr"]= {["text"]: this.surveyForm.controls['general_os_fr_s'].value};
    this.response.acoustics= new Acoustics();
    this.response.acoustics.ratings["noise_level_os_fr"]=this.surveyForm.controls['noise_level_os_fr_a'].value;
    this.response.acoustics.ratings["sound_proofing_os_fr"]=this.surveyForm.controls['sound_proofing_os_fr'].value;
    this.response.acoustics.comments["general_os_fr"]= {["text"]: this.surveyForm.controls['general_os_fr_a'].value};
    this.response.acoustics.comments["disturbing_noises_os_fr"]= {["text"]: this.surveyForm.controls['disturbing_noises_os_fr'].value};
    this.response.acoustics.comments["protection_os_fr"]= {["text"]: this.surveyForm.controls['protection_os_fr'].value};
    this.response.air= new Air();
    this.response.air.ratings["general_os_fr"]=this.surveyForm.controls['general_os_fr_air'].value;
    this.response.air.comments["general_os_fr"]= {["text"]: this.surveyForm.controls['comment_air'].value};
    this.response.luminosity = new Luminosity();
    this.response.luminosity.ratings["natural_os_fr"]=this.surveyForm.controls['natural_os_fr'].value;
    this.response.luminosity.ratings["artificial_os_fr"]=this.surveyForm.controls['artificial_os_fr'].value;
    this.response.luminosity.comments["general_os_fr"]= {["text"]: this.surveyForm.controls['comment_light'].value};
    this.response.temperature = new Temperature();
    this.response.temperature.ratings["heating_os_fr"]=this.surveyForm.controls['heating_os_fr'].value;
    this.response.temperature.ratings["temperature_os_fr"]=this.surveyForm.controls['temperature_os_fr'].value;
    this.response.temperature.ratings["air_conditioning_os_fr"]=this.surveyForm.controls['air_conditioning_os_fr'].value;
    this.response.temperature.comments["general_os_fr"]= {["text"]: this.surveyForm.controls['comment_temperature'].value};
    this.response.summary= new Summary();
    this.response.summary.comments["improvements_os_fr"]= {["text"]: this.surveyForm.controls['improvements_os_fr'].value};
    this.response.summary.comments["other_rooms_os_fr"]= {["text"]: this.surveyForm.controls['other_rooms_os_fr'].value};
  }
  
  resetForm(){
  }

}


