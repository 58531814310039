import { Injector,NgModule } from "@angular/core";
import { SurveyComponents } from './components/_module';
import { setSurveyInjector } from "./common/survey.injector";
import { FormsModule} from "@angular/forms";
import { RouterLink } from "@angular/router";
import { RadioButtonModule } from 'primeng/radiobutton';
import { ReactiveFormsModule } from "@angular/forms";
import { ToolbarModule } from 'primeng/toolbar';
import { SurveyRoutingModule } from "./survey-routing.module";
import { AccordionModule } from 'primeng/accordion';
import { CommonModule } from "@angular/common";
import { ResponseAverageItemComponent } from './components/response-average-item/response-average-item.component';
import { CommentsItemsComponent } from './components/comments-items/comments-items.component';
import { ButtonModule } from 'primeng/button';
import { OktaAuthModule } from "@okta/okta-angular";
import { RatingModule } from 'primeng/rating';
import { DialogModule } from "primeng/dialog";
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        ...SurveyComponents,
        ResponseAverageItemComponent,
        CommentsItemsComponent,
      ],
    imports:[
      FormsModule,
      RouterLink,
      RadioButtonModule,
      ReactiveFormsModule,
      ToolbarModule,
      SurveyRoutingModule,
      AccordionModule,
      CommonModule,
      ButtonModule,
      OktaAuthModule,
      RatingModule,
      DialogModule,
      TranslateModule
    ],
    exports:[
      ...SurveyComponents
    ],
   
})

export class SurveyModule {
  constructor(injector: Injector) {
    setSurveyInjector(injector);
  }
}