import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app/app.component';
import { RootComponents } from './components/_module';
import { CoreModule } from '../core/core.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslationsLoaderFactory } from '../core/common/translation/translations.loader';
import { ApiInterceptor } from '../core/interceptor/api.interceptor';
import { OktaAuth } from '@okta/okta-auth-js';
import { SurveyModule } from '../modules/survey/survey.module';
import {
  OKTA_CONFIG,
  OktaAuthGuard,
  OktaAuthModule
} from '@okta/okta-angular';
import { NgxSpinnerModule } from 'ngx-spinner';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SpinnerHandler } from '../core/common/spinner/spinner.handler';

@NgModule({
  declarations: [
    ...RootComponents
  ],
  imports: [
    CoreModule,
    AppRoutingModule,
    NgxSpinnerModule,
    HttpClientModule,
    SurveyModule,
    OktaAuthModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: TranslationsLoaderFactory,
        deps: [HttpClient],
      },
      isolate: true
    }),
  ],
  providers: [
    SpinnerHandler,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    { 
      provide: OKTA_CONFIG, 
      useFactory: () => {
        const oktaAuth = new OktaAuth(environment.oidc);
        return {
          oktaAuth,
          onAuthRequired: (oktaAuth: OktaAuth, injector: Injector) => {
            const triggerLogin = () => {
              const router = injector.get(Router);
              router.navigate(['/login']);
            };
            if (!oktaAuth.authStateManager.getPreviousAuthState()?.isAuthenticated) {
              triggerLogin();
            }
          }  
        }
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
