export const environment = {
  production: false,
  apiEndpoints:"https://dev.api.espace-client.ecume-service.fr/",
  hostName: "https://dev.espace-client.ecume-service.fr/",
  oidc: {
    clientId: '0oa5dbst5gXvRNYJg0x7',
    issuer: 'https://ecume.oktapreview.com/oauth2/default',
    redirectUri: 'https://dev.espace-client.ecume-service.fr/login/callback',
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    onSessionExpired: () => {
      localStorage.clear();
      location.reload();
    }
  },
  widget: {
    useInteractionCodeFlow: 'true',
  }
};