import { Component, Input, SimpleChanges } from '@angular/core';
import { SurveyService } from '../../services/survey.service';
import { BlobService} from '../../../../core/common/service/blob.service';
import * as FileSaver from 'file-saver';
import { environment } from 'src/environments/environment.dev';
import { Router } from '@angular/router';
import { ProjectDetails } from 'src/app/modules/project/entities/projectdetails.entity';
import { SurveyContainer } from '../../common/survey.container';

@Component({
  selector: 'app-occupant-feedback',
  templateUrl: './occupant-feedback.component.html',
  styleUrls: ['./occupant-feedback.component.scss']
})
export class OccupantFeedbackComponent extends SurveyContainer {

  @Input() projectDetails: ProjectDetails;
  
  surveyResponses: any;
  hostName: string = environment.hostName;
  ratings:any; //toverify
  calculateAvgSecWise:any=0;  //toverify
  ratingsSecLevel:any;  //toverify
  count:any=0;  //toverify
  finalcalculateAvgSecWise:any;
  visible: any;
  response: any;

  obj:any={
    space:{
      room_os_fr: "",
      decoration_os_fr: "",
      flow_os_fr: ""
    },
    acoustics:{
      noise_level_os_fr:"",
      sound_proofing_os_fr:""
    },
    air:{
      general_os_fr:""
    },
    luminosity:{
      natural_os_fr:"",
      artificial_os_fr:""
    },
    temperature:{
      heating_os_fr:"",
      temperature_os_fr:"",
      air_conditioning_os_fr:""
    }
 };
 noOfComments: any;

  constructor(private blobService: BlobService) {
    super();
  }

  ngOnInit(){
    this.spinner.show()
    this.surveyService.getSurveyResponsesByProjectId(this.projectDetails.id).subscribe((res: any) => {
      this.surveyResponses = res;
      this.spinner.hide()
    });
  }

  copyQuizURL(urlElement: any) {
    urlElement.select();
    document.execCommand('copy');
    urlElement.setSelectionRange(0, 0);
  }

  getPersonsReactionByChoices(section: any, question: any) {
    if(this.surveyResponses){
      this.surveyResponses = (this.surveyResponses?.map((x: any) => {
        if(x.answers)
          return JSON?.parse(x?.answers)
        else
          return x;
      }));
      const resp = this.surveyResponses?.map((x: any) => x[section]?.ratings[question]);
      return this.getFormatedDataByChoices(resp);
    }
    else
    {
      return null;
    }
  }

  getCommentsForSection(section: any, question: any):any{
    if(this.surveyResponses){
      this.surveyResponses = (this.surveyResponses?.map((x: any) => {
        if(x.answers)
          return JSON?.parse(x?.answers)
        else
          return x;
      }));
        const resp = this.surveyResponses?.map((x: any) => x[section]?.comments[question]["text"]);
        this.response = resp.filter((x: any) => x != "" && x != undefined);
        return this.response;
    }
    else
    {
      return null;
    }
  }

  getNoOfComments(section: any) {
    if(this.surveyResponses){
      this.surveyResponses = (this.surveyResponses?.map((x: any) => {
        if(x.answers)
          return JSON?.parse(x?.answers)
        else
          return x;
      }));
        const noOfResp = this.surveyResponses?.map((x: any) => x[section]?.comments)?.map((y: any) => {
          if(y) {
            return Object.keys(y).map(o => y[o]).filter(z => z);
          } else {
            return null;
          }
        });
        this.noOfComments = noOfResp.flat(1).filter((x: any) => x?.text != "" && x?.text != undefined).length
        return this.noOfComments;
    }
    else
    {
      return null;
    }
  } 

  getFormatedDataByChoices(modifiedData: any) {
    const answer = {
      VerySatisfaying:0,
      QuiteSatisfying: 0,
      NotSatisfying: 0,
      NotAtAllSatisfactory: 0,
      WithoutOpinion: 0
    }
    if(modifiedData){
    modifiedData?.forEach((x:any)=>{
      switch (x) {
        case '2':
          answer.VerySatisfaying++;
          break;
        case '1':
          answer.QuiteSatisfying++;
          break;
        case '-1':
          answer.NotSatisfying++;
          break;
        case '-2':
          answer.NotAtAllSatisfactory++;
          break;
        case '-500':
          answer.WithoutOpinion++;
          break;
        default:
          break;
      }
    })
  }
    return answer;
  }


  calculateRatingsSecLevel(event:any, section: string, question: string){
    this.ratings=event;
    this.obj[section][question] = event;
    this.calculateAverageAtSectionLevel();
  }

  ngAfterViewInit() {
    console.log(this.obj)
  }

  calculateAverageAtSectionLevel(){
    if(this.ratings=='Quite Satisfying'){
        this.calculateAvgSecWise+=3;
        this.count++;
    }
    if(this.ratings=='NotSatisfying'){
        this.calculateAvgSecWise+=2;
        this.count++;
    }
    if(this.ratings=='Very Satisfying'){
      this.calculateAvgSecWise+=4;
      this.count++;
    }
    if(this.ratings=='Not at all satisfactory'){
      this.calculateAvgSecWise+=1;
      this.count++;
    }
    if(this.ratings=='No opinion'){
      this.calculateAvgSecWise+=0;
    }
    
    this.calculateWeightedAverageSecWise();
  }

  calculateWeightedAverageSecWise(){
    this.finalcalculateAvgSecWise=this.calculateAvgSecWise/this.count;
    if(this.finalcalculateAvgSecWise>=1 && this.finalcalculateAvgSecWise<1.5){
      this.ratingsSecLevel="Not at all satisfactory"
    } 
    else if(this.finalcalculateAvgSecWise>=1.5 && this.finalcalculateAvgSecWise<2.5){
      this.ratingsSecLevel="Not Satisfying"
    } 
    else if(this.finalcalculateAvgSecWise>=2.5 && this.finalcalculateAvgSecWise<3.5){
      this.ratingsSecLevel="Quite Satisfying"
    } 
    else if(this.finalcalculateAvgSecWise>=3.5 && this.finalcalculateAvgSecWise<=4.0){
      this.ratingsSecLevel="Very Satisfying"
    } 
    else{
      this.ratingsSecLevel="No opinion"
    } 
  }

  getExportData(id:string){
    this.surveyService.getExportFile(id).subscribe((x:any)=>{
      FileSaver.saveAs(this.blobService.convertBytetoBlob(x), x.fileDownloadName);
    });
  }  

  openInNewTab() {
    const url = `/#/survey/${this.projectDetails.id}`;
    window.open(url, '_blank');
  }

}
