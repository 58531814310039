import { Component } from '@angular/core';
import { Input } from '@angular/core';
import { SurveyContainer } from '../../common/survey.container';
@Component({
  selector: 'app-comments-items',
  templateUrl: './comments-items.component.html',
  styleUrls: ['./comments-items.component.scss']
})
export class CommentsItemsComponent extends SurveyContainer {
  @Input() item:any;
  show: any = true;
  label: any;
  arrow: any;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  // showComments(value: any){
  //   console.log(value);
  //   this.show = !this.show;
  //   this.label = !this.label;
  //   this.arrow = !this.arrow;
  // }
}
