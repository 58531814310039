//#region Imports
import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
//#endregion Imports

//#region Internal Imports

//#endregion Internal Imports

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  //#region Constructor Methods
  constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth) {}
  //#endregion Constructor Methods

  //#region Intercept Methods
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const accessToken = this.oktaAuth.getAccessToken();
    request = request.clone({
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + accessToken,
        'content-type': 'application/json; charset=utf-8'
      }),
    });
    return next.handle(request);
  }
  //#endregion Intercept Methods

  //#region Helpers Methods
  //#endregion Helpers Methods
}
