import { Space } from "./space";
import { Air } from "./air";
import { Acoustics } from "./acoustics";
import { Luminosity } from "./luminosity";
import { Summary } from "./summary";
import { Temperature } from "./temperature";

export class SurveyResponse{
    id?: string;
    air?: Air;
    space?:Space;
    luminosity?:Luminosity;
    acoustics?:Acoustics;
    summary?:Summary;
    temperature?:Temperature;

    
}