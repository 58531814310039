//#region Imports
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AvatarModule } from 'primeng/avatar';
import { TranslateModule } from '@ngx-translate/core';
//#endregion Imports

//#region internal Imports
import { AuthenticationComponents } from './authentication/components/_module';
import { LayoutComponents } from './layout/_module';
import { AuthenticationService } from './authentication/services/authentication.service';
//#endregion internal Imports

@NgModule({
  declarations: [
    ...AuthenticationComponents,
    ...LayoutComponents
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    AvatarModule,
    TranslateModule
  ],
  providers: [
    AuthenticationService
  ],
  exports: []
})
export class CoreModule {}
