//#region Imports
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
//#endregion Imports

//#region Internal Imports
//#endregion Internal Imports

@Injectable()
export class SpinnerHandler {
  //#region Constructor Methods
  constructor(private spinner: NgxSpinnerService) {}
  //#endregion Constructor Methods

  //#region Show-Hide Methods
  show(name? : string) {
    if (!name)
      name = 'applicationSpinner';

    this.spinner.show(name);
  }

  hide(name? : string) {
    if (!name)
      name = 'applicationSpinner';

    this.spinner.hide(name);
  }
  //#endregion Show-Hide Methods
}
