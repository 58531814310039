<div class="chart" *ngIf="res" [ngClass]="color">
    <div class="chart-heading flex align-items-center justify-content-between border-bottom-1 pb-2 mb-3">
        <div class="text-700">{{ratings}} </div>
        <i class="pi pi-thumbs-{{direction}}-fill"></i>
    </div>
    <div class="text-900">
        <div>
            <div *ngIf="res.VerySatisfaying" style="vertical-align: inherit;">
                <span style="vertical-align: inherit;">{{ 'labels.response1' | translate }}: {{res.VerySatisfaying}} {{ 'labels.people' | translate }}</span>
            </div>
        </div>
        <div>
            <div *ngIf="res.QuiteSatisfying" style="vertical-align: inherit;">
                <span style="vertical-align: inherit;">{{ 'labels.response2' | translate }}: {{res.QuiteSatisfying}} {{ 'labels.people' | translate }}</span>
            </div>
        </div>
        <div>
            <div *ngIf="res.NotSatisfying" style="vertical-align: inherit;">
                <span style="vertical-align: inherit;">{{ 'labels.response3' | translate }}: {{res.NotSatisfying}} {{ 'labels.people' | translate }}</span>
            </div>
        </div>
        <div>
            <div *ngIf="res.NotAtAllSatisfactory" style="vertical-align: inherit;">
                <span style="vertical-align: inherit;">{{ 'labels.response4' | translate }}: {{res.NotAtAllSatisfactory}} {{ 'labels.people' | translate }}</span>
            </div>
        </div>
        <div>
            <div *ngIf="res.WithoutOpinion" style="vertical-align: inherit;">
                <span style="vertical-align: inherit;">{{ 'labels.response5' | translate }}: {{res.WithoutOpinion}} {{ 'labels.people' | translate }}</span>
            </div>
        </div>
    </div>
</div>