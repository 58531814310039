import { Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../translation/language.service';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { User } from '../../authentication/model/user.model';
import { Router } from '@angular/router';
import { SpinnerHandler } from '../spinner/spinner.handler';

export abstract class FeatureContainer {
    //#region Services
  protected translate: TranslateService;
  public currentUser: User;
  public router: Router
  protected spinner: SpinnerHandler;
  //#endregion Services
  protected constructor(protected injector: Injector) {
    this.translate = injector.get(TranslateService);    
    this.currentUser = injector.get(AuthenticationService).currentUserValue;
    this.spinner = injector.get(SpinnerHandler);
    if(this.currentUser.preferredLanguage)
      this.translate.use(this.currentUser.preferredLanguage);
    else 
      this.translate.use(injector.get(LanguageService).current);
    this.router = injector.get(Router);
  }
}
