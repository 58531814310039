<div class="vh-100 d-flex justify-content-center align-items-center">
    <div class="col-md-6">
        <div class="card bg-white shadow p-5">
            <div class="mb-4 text-center">
                <i class="pi pi-check-circle text-6xl text-green-600"></i>
            </div>
            <div class="text-center">
                <h2>{{ 'messages.thankYou' | translate }}</h2>
                <p class="text-base text-600">{{ 'messages.thankYou2' | translate }}</p>
            </div>
        </div>
    </div>
</div>